<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Settings</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <div class="row align-items-center">
              <div class="col-8">
                <h3 class="mb-0" id="users_main_heading">Settings</h3>
              </div>
            </div>
          </template>

          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
  import { Table, TableColumn, Select, Option, Loading } from "element-ui";
  import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
  import tableMixin from "@/helpers/tableMixin";

  export default {
    components: {
      Table,
      TableColumn,
      Select,
      Option,
      Loading,
      RouteBreadCrumb
    },
    mixins: [tableMixin],
  };
</script>

<style>
  .no-border-card .card-footer {
    border-top: 0;
  }

  .el-table .cell {
    word-break: normal;
  }

  .el-table--enable-row-hover .el-table__body tr:hover>td {
    background-color: #f6f9fc !important;
    cursor: pointer;
  }
  </style>